
<script setup lang="ts">
import { CmsSlot } from "@shopware-pwa/types";
import { ElementConfig } from "@shopware-pwa/composables-next";
import { SwiperOptions } from "swiper/types";

type StageSliderElementConfig = {
  objItems: ElementConfig<object[]>;
  navigationArrows?: ElementConfig<boolean>;
  navigation?: ElementConfig<boolean>;
  loop?: ElementConfig<boolean>;
  autoplay?: ElementConfig<any>;
};

type CmsElementStageSlider = CmsSlot & {
  type: "stageSlider";
  config: StageSliderElementConfig;
  data: {
    apiAlias: "cms_slot";
  };
};

const props = defineProps<{
  content: CmsElementStageSlider;
  blockPosition?: number;
}>();

const { formatLink } = useInternationalization();
const { getConfigValue } = useCmsElementConfig(props.content);
const slides: any = [...getConfigValue("objItems")].filter(slide => {
  return (slide && slide.hasOwnProperty('active')) ? slide.active : true;
});
const navigationArrows: boolean = getConfigValue("navigationArrows");
const navigationDots: boolean = getConfigValue("navigationDots");

const loop: boolean = getConfigValue("loop");
const autoPlay: any = getConfigValue("autoPlay");

const paginationConfig: SwiperOptions["pagination"] = {
  enabled: true,
};

const color = ref();
const colorMobile = ref();
const navigationColor = ref();
const setSwiperColor = (swiper: any) => {
  color.value = swiper.slides[swiper.activeIndex].dataset.color;
  colorMobile.value = swiper.slides[swiper.activeIndex].dataset.colorMobile;
  navigationColor.value = swiper.slides[swiper.activeIndex].dataset.navigationColor;

  useHead({
    bodyAttrs: {
      "data-navigation-color": navigationColor.value
    },
  });
};
</script>

<template>
  <nuxt-error-boundary @error="() => {}">
    <Swiper
      class="stage-slider w-full"
      :data-color="color"
      :data-color-mobile="colorMobile"
      :data-navigation-color="navigationColor"
      data-testid="stage-slider"
      :modules="[SwiperPagination, SwiperNavigation, SwiperAutoplay, SwiperKeyboard]"
      :loop="loop"
      :pagination="navigationDots"
      :navigation="navigationArrows"
      :autoplay="autoPlay ? {
        delay: 8000,
        disableOnInteraction: true,
      } : false"
      :keyboard="{ enabled: true }"
      :slides-per-view="1"
      :space-between="0"
      :grab-cursor="true"
      :auto-height="true"
      @init="setSwiperColor"
      @slideChange="setSwiperColor"
    >
      <template  v-for="(slide, index) in slides" :key="index">
        <SwiperSlide :data-color="slide.color ?? undefined" :data-color-mobile="slide.colorMobile ?? undefined" :data-navigation-color="slide.navigationColor ?? undefined" :data-swiper-autoplay="slide.autoplayTime ? slide.autoplayTime * 1000 : undefined">
          <CmsElementStageSlide
            :type="slide.type ?? 'image'"
            :content-order="slide.contentOrder ?? undefined"
            :content-max-width="slide.contentMaxWidth ?? undefined"
            :content-align="slide.contentAlign ?? undefined"
            :content-position="slide.contentPosition ?? undefined"
            :title="slide.title ?? undefined"
            :titleSize="slide.titleSize ?? undefined"
            :titleWeight="slide.titleWeight ?? undefined"
            :titleStyle="slide.titleStyle ?? undefined"
            :subtitle="slide.subtitle ?? undefined"
            :subtitleSize="slide.subtitleSize ?? undefined"
            :subtitleWeight="slide.subtitleWeight ?? undefined"
            :subtitleStyle="slide.subtitleStyle ?? undefined"
            :color="slide.color ?? undefined"
            :colorMobile="slide.colorMobile ?? undefined"
            :background-color="slide.backgroundColor ?? undefined"
            :gradient="slide.gradient ?? undefined"
            :gradient-intensity="slide.gradientIntensity ?? undefined"
            :gradient-position="slide.gradientPosition ?? undefined"
            :media="slide.media ?? undefined"
            :media-url="slide.media?.id ? (content.data?.medias?.[slide.media.id]?.media?.url ?? undefined) : undefined"
            :block-position="blockPosition"
          >
            <ul v-if="slide.buttons && slide.buttons.length > 0" class="inline-flex flex-wrap justify-center gap-3 lg:gap-5">
              <li v-for="button in slide.buttons">
                <SharedFormButton :type="button.type" :layout="button.variant" :to="formatLink('/' + button.url)" :size="button.size"> {{ button.linkText }}</SharedFormButton>
              </li>
            </ul>
          </CmsElementStageSlide>
        </SwiperSlide>
      </template>
    </Swiper>
  </nuxt-error-boundary>
</template>
